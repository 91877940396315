{
  "name": "main-app",
  "version": "0.0.0",
  "scripts": {
    "start": "npm run translate && ng serve --o",
    "prepare": "husky install && npm run translate",
    "prepare-env": "cd src/environments && cp environment.example.ts environment.ts",
    "lint": "eslint --fix",
    "prettier": "prettier --write",
    "translate": "node run-translate.mjs",
    "link:ui": "npx yarn link @q9elements/ui-kit"
  },
  "lint-staged": {
    "./**/*.{scss}": [
      "npm run prettier"
    ],
    "./**/*.{ts,html}": [
      "npm run lint",
      "npm run prettier"
    ]
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^16.2.0",
    "@angular/common": "^16.2.0",
    "@angular/compiler": "^16.2.0",
    "@angular/core": "^16.2.0",
    "@angular/fire": "^16.0.0",
    "@angular/forms": "^16.2.0",
    "@angular/material": "^16.2.0",
    "@angular/platform-browser": "^16.2.0",
    "@angular/platform-browser-dynamic": "^16.2.0",
    "@angular/router": "^16.2.0",
    "@q9elements/ui-kit": "1.4.0-21362.43",
    "lodash": "^4.17.21",
    "ng2-google-charts": "^7.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.13.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^16.2.8",
    "@angular-eslint/builder": "16.3.1",
    "@angular-eslint/eslint-plugin": "16.3.1",
    "@angular-eslint/eslint-plugin-template": "16.3.1",
    "@angular-eslint/schematics": "16.3.1",
    "@angular-eslint/template-parser": "16.3.1",
    "@angular/cli": "^16.2.8",
    "@angular/compiler-cli": "^16.2.0",
    "@angular/localize": "^16.2.12",
    "@types/lodash": "^4.14.202",
    "@typescript-eslint/eslint-plugin": "^6.11.0",
    "@typescript-eslint/parser": "^6.11.0",
    "eslint": "^8.51.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-import-resolver-typescript": "^3.6.1",
    "eslint-plugin-import": "^2.29.0",
    "eslint-plugin-prettier": "^5.0.1",
    "eslint-plugin-unused-imports": "^3.0.0",
    "husky": "^8.0.3",
    "lint-staged": "^15.1.0",
    "prettier": "^3.1.0",
    "typescript": "~5.1.3"
  }
}
