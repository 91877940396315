import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire/compat';
import {getDatabase, provideDatabase} from '@angular/fire/database';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {provideEffectsManager} from '@ngneat/effects-ng';
import {
  ConfigService,
  CoreModule,
  enableKendoTheming,
  provideAkitaDevtools,
  provideAppInitializers,
  provideAuthInterceptor
} from '@q9elements/ui-kit/common';
import {LoadingPlaceholderModule, PageLoaderComponent} from '@q9elements/ui-kit/components';
import {IntercomModule} from 'ng-intercom';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {provideErrorInterceptor} from './shared/interceptors/error.interceptor';
import {provideErrorHandler} from './shared/models/error-handler';
import {provideRouteReuseStrategy} from './shared/routing/route-reuse-strategy';

ConfigService.setConfig({environment, appName: 'main'});

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    PageLoaderComponent,
    LoadingPlaceholderModule,
    RouterModule,
    MatDialogModule,
    MatSnackBarModule,
    IntercomModule.forRoot({
      appId: ConfigService.environment.INTERCOM_APP_ID as string,
      updateOnRouterChange: true
    }),
    AngularFireModule.initializeApp(environment.fbConfig!),
    provideFirebaseApp(() => initializeApp(environment.fbConfig!)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideDatabase(() => getDatabase())
  ],
  providers: [
    !environment.production ? provideAkitaDevtools() : [],
    provideAuthInterceptor(),
    provideErrorInterceptor(),
    provideAppInitializers(),
    provideErrorHandler(),
    provideEffectsManager(),
    provideRouteReuseStrategy(),
    enableKendoTheming(true)
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
